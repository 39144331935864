<template>
  <div>
    <!-- Modal Messages-->
    <b-modal
      id="modal-messages"
      v-model="modalMessage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Messages"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              v-if="messagesList.length > 0"
              class="list-group"
              style="height:300pxwidth:100%overflow-y:scroll"
            >
              <input
                id="materialRequestId"
                ref="invoiceMessage"
                v-model="materialRequestId"
                type="hidden"
                name="materialRequestId"
              />
              <li
                v-for="item in messagesList"
                :key="item"
                class="list-group-item"
              >
                <div
                  v-if="userId.toString() == item.user.user_id.toString()"
                  class="float-right"
                >
                  <h5>Me</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.material_request_chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.material_request_chat_text }}</p>
                </div>
                <div
                  v-if="userId.toString() != item.user.user_id.toString()"
                  class="float-left"
                >
                  <h5>{{ item.user.user_fullname }}</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.material_request_chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.material_request_chat_text }}</p>
                </div>
              </li>
            </ul>
            <div v-if="messagesList.length == 0">
              No message
            </div>
            <br />
            <form
              id="send_message_form"
              method="post"
              @submit.prevent="sendMessage"
              action=""
            >
              <b-row>
                <b-col cols="11" md="9">
                  <input
                    v-model="messageBox"
                    type="text"
                    class="form-control"
                    placeholder="Write your message"
                  />
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendMessage()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- Modal Upload-->
    <b-modal
      id="modal-upload"
      v-model="modalUpload"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Documents"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              v-if="documentsList.length > 0"
              class="list-group"
              style="height:300pxwidth:100%overflow-y:scroll"
            >
              <input
                id="materialRequestId"
                ref="invoiceMessage"
                v-model="materialRequestId"
                type="hidden"
                name="materialRequestId"
              />
              <li
  v-for="item in documentsList"
  :key="item"
  class="list-group-item d-flex justify-content-between align-items-center"
  style="cursor: pointer;"
>
  <span style="font-size:9px">{{ item.document_file_name }}</span>
  <div>
    <b-button
  :disabled="item.document_old"
  variant="primary"
  size="sm"
  class="mr-1"
  @click.stop="downloadAttachment(item)"
>
      <feather-icon icon="DownloadIcon" />
    </b-button>
    <b-button variant="danger" size="sm" @click.stop="deleteAttachment(item)">
      <feather-icon icon="TrashIcon" />
    </b-button>
  </div>
</li>
            </ul>
            <div v-if="documentsList.length == 0">
              No Files
            </div>
            <br />
            <form
              id="send_file_form"
              method="post"
              @submit.prevent="sendfiles"
              action=""
            >
              <b-row>
                <b-col cols="11" md="9">
          <b-form-file
            id="file"
            ref="uploadFile"
            placeholder="Choose one file or drop it here..."
drop-placeholder="Drop a file here..."
type="file"
@change="handleFilesUpload"
          />
          <div class="alert alert-info" role="alert">
                Max file upload is 2MB
              </div>
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendDocument()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-card no-body class="mb-0">
      <b-tabs>
        <b-tab title="On Going">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilter" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilter" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportMR()" :disabled="isLoadingExport">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    v-if="hasPermission('material_request_create') == true"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Material Request</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refMaterialRequestListTable"
            class="position-relative"
            :items="fetchMaterialRequests"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.messageFlag == true"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                  v-if="data.item.material_request_is_received == true"
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                />
                <feather-icon
                v-if="data.item.material_request_has_files == true"
                icon="FileIcon" />
                &nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: To -->
            <template #cell(To)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  cutString(data.item.material_request_to, 30)
                }}</span>
              </div>
            </template>
            <!-- Column: From -->
            <template #cell(From)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  cutString(
                    data.item.material_request_cc
                      ? data.item.material_request_cc
                      : "-",
                    30
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_is_center == true
                    ? "Head Office"
                    : "Local"
                }}</span>
              </div>
            </template>
            <!-- Column: Reason -->
            <template #cell(Reason)="data">
              {{
                cutString(
                  data.item.material_request_reason
                    ? data.item.material_request_reason
                    : "-",
                  30
                )
              }}
            </template>
            <!-- Column: QtyReq -->
            <template #cell(QtyReq)="data"
              >{{ data.item.material_request_qty_requested }}
            </template>
            <!-- Column: QtyAcc -->
            <template #cell(QtyAcc)="data">
              {{
                data.item.material_request_qty_accepted
                  ? data.item.material_request_qty_accepted
                  : 0
              }}
            </template>
            <!-- Column: QtySent -->
            <template #cell(QtySent)="data">
              {{
                data.item.material_request_qty_sent
                  ? data.item.material_request_qty_sent
                  : 0
              }}
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.material_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="closeMR(data.item.material_request_id)" v-if="userRole=='user'">
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Close MR</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item.material_request_id)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="showDocuments(data.item.material_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('material_request_update') == true &&
                      data.item.material_request_is_accepted_by_supervisor ==
                        false && data.item.material_request_is_accepted_by_warehouse == false && data.item.material_request_is_received == false
                  "
                  @click="editMaterialRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('material_request_confirm') == true &&
                      data.item.material_request_is_accepted_by_supervisor ==
                        false
                  "
                  @click="editMaterialRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('material_request_confirm') == true &&
                      data.item.material_request_is_accepted_by_supervisor ==
                        false
                  "
                  @click="confirmMaterialRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    hasPermission('material_request_send') == true &&
                      data.item.material_request_is_accepted_by_warehouse ==
                        false &&
                      data.item.material_request_is_accepted_by_supervisor ==
                        true
                  "
                  @click="sendMaterialRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Send</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deleteMaterialRequest(data.item.material_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.from }} to
                   {{ dataMeta.to }} of
                  {{ dataMeta.of }} entries</span
                >
                <span style="padding-left: 10px;">
                  <feather-icon
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                /> = Accepted,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Finished">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-finished" v-model="startDateFilterFinished" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-finished" v-model="endDateFilterFinished" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportMRFinished()" :disabled="isLoadingExportFinished">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportFinished"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageFinished"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryFinished"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    v-if="hasPermission('material_request_create') == true"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Material Request</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refMaterialRequestListTableFinished"
            class="position-relative"
            :items="fetchMaterialRequestsFinished"
            responsive
            :fields="tableColumnsFinished"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.messageFlag == true"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                  v-if="data.item.material_request_is_received == true"
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                />
                <feather-icon
                v-if="data.item.material_request_has_files == true"
                icon="FileIcon" />
                &nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: To -->
            <template #cell(To)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_to
                }}</span>
              </div>
            </template>
            <!-- Column: From -->
            <template #cell(From)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_cc
                    ? data.item.material_request_cc
                    : "-"
                }}</span>
              </div>
            </template>
            <!-- Column: Reason -->
            <template #cell(Reason)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.material_request_reason
                    ? data.item.material_request_reason
                    : "-"
                }}
              </b-badge>
            </template>
            <!-- Column: QtyReq -->
            <template #cell(QtyReq)="data"
              >{{ data.item.material_request_qty_requested }}
            </template>
            <!-- Column: QtyAcc -->
            <template #cell(QtyAcc)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.material_request_qty_accepted
                    ? data.item.material_request_qty_accepted
                    : 0
                }}
              </b-badge>
            </template>
            <!-- Column: QtySent -->
            <template #cell(QtySent)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.material_request_qty_sent
                    ? data.item.material_request_qty_sent
                    : 0
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.material_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Show Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item.material_request_id)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.material_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaFinished.from }} to
                  {{ dataMetaFinished.to }} of
                  {{ dataMetaFinished.of }} entries</span
                >
                <span style="padding-left: 10px;">
                  <feather-icon
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                /> = Accepted,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageFinished"
                  :total-rows="totalItemsFinished"
                  :per-page="perPageFinished"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="In Queue">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-InQueue" v-model="startDateFilterInQueue" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-InQueue" v-model="endDateFilterInQueue" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportMRFinished()" :disabled="isLoadingExportInQueue">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportInQueue"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageInQueue"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryInQueue"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    v-if="hasPermission('material_request_create') == true"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Material Request</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refMaterialRequestListTableInQueue"
            class="position-relative"
            :items="fetchMaterialRequestsInQueue"
            responsive
            :fields="tableColumnsInQueue"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.messageFlag == true"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                  v-if="data.item.material_request_is_received == true"
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                />
                <feather-icon
                v-if="data.item.material_request_has_files == true"
                icon="FileIcon" />
                &nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: To -->
            <template #cell(To)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_to
                }}</span>
              </div>
            </template>
            <!-- Column: From -->
            <template #cell(From)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_cc
                    ? data.item.material_request_cc
                    : "-"
                }}</span>
              </div>
            </template>
            <!-- Column: Reason -->
            <template #cell(Reason)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.material_request_reason
                    ? data.item.material_request_reason
                    : "-"
                }}
              </b-badge>
            </template>
            <!-- Column: QtyReq -->
            <template #cell(QtyReq)="data"
              >{{ data.item.material_request_qty_requested }}
            </template>
            <!-- Column: QtyAcc -->
            <template #cell(QtyAcc)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.material_request_qty_accepted
                    ? data.item.material_request_qty_accepted
                    : 0
                }}
              </b-badge>
            </template>
            <!-- Column: QtySent -->
            <template #cell(QtySent)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.material_request_qty_sent
                    ? data.item.material_request_qty_sent
                    : 0
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.material_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="closeMR(data.item.material_request_id)" v-if="userRole=='user'">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Close MR</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="showMessages(data.item.material_request_id)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.material_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaInQueue.from }} to
                  {{ dataMetaInQueue.to }} of
                  {{ dataMetaInQueue.of }} entries</span
                >
                <span style="padding-left: 10px;">
                  <feather-icon
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                /> = Accepted,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageInQueue"
                  :total-rows="totalItemsInQueue"
                  :per-page="perPageInQueue"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Void">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-void" v-model="startDateFilterVoid" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-void" v-model="endDateFilterVoid" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportMRVoid()" :disabled="isLoadingExportVoid">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportVoid"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageVoid"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryVoid"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    v-if="hasPermission('material_request_create') == true"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Material Request</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refMaterialRequestListTableVoid"
            class="position-relative"
            :items="fetchMaterialRequestsVoid"
            responsive
            :fields="tableColumnsVoid"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.messageFlag == true"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                  v-if="data.item.material_request_is_received == true"
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                />
                <feather-icon
                v-if="data.item.material_request_has_files == true"
                icon="FileIcon" />
                &nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: To -->
            <template #cell(To)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_to
                }}</span>
              </div>
            </template>
            <!-- Column: From -->
            <template #cell(From)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.material_request_cc
                    ? data.item.material_request_cc
                    : "-"
                }}</span>
              </div>
            </template>
            <!-- Column: Reason -->
            <template #cell(Reason)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.material_request_reason
                    ? data.item.material_request_reason
                    : "-"
                }}
              </b-badge>
            </template>
            <!-- Column: QtyReq -->
            <template #cell(QtyReq)="data"
              >{{ data.item.material_request_qty_requested }}
            </template>
            <!-- Column: QtyAcc -->
            <template #cell(QtyAcc)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.material_request_qty_accepted
                    ? data.item.material_request_qty_accepted
                    : 0
                }}
              </b-badge>
            </template>
            <!-- Column: QtySent -->
            <template #cell(QtySent)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.material_request_qty_sent
                    ? data.item.material_request_qty_sent
                    : 0
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.material_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
        <!-- Column: VoidBy -->
        <template #cell(VoidBy)="data">
          <b-badge pill class="text-capitalize">
            {{
              data.item.deleter
                ? data.item.deleter.user_fullname
                : "-"
            }}
          </b-badge>
        </template>
        <!-- Column: VoidTime -->
        <template #cell(VoidTime)="data">
          {{
            data.item.material_request_deleted_time?
            dateSimple(
              data.item.material_request_deleted_time,
              "YYYY-MM-DD HH:mm:ss"
            ):"-"
          }}
        </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaVoid.from }} to
                  {{ dataMetaVoid.to }} of
                  {{ dataMetaVoid.of }} entries</span
                >
                <span style="padding-left: 10px;">
                  <feather-icon
                  style="color:#00FF00"
                  icon="CheckCircleIcon"
                /> = Accepted,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageVoid"
                  :total-rows="totalItemsVoid"
                  :per-page="perPageVoid"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTabs,
  BTab,
  BFormDatepicker,
  BFormFile,
} from "bootstrap-vue"
import axios from "axios"
import vSelect from "vue-select"
import store from "@/store"
import moment from "moment"
import { getUserData } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
import { saveAs } from 'file-saver'
import MaterialRequestList from "./List"

// Unchanged imports
const messageBox = ""
const to = ""
const cc = ""
const reason = ""
const materialRequestId = ""
const messagesList = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const userRole = ""
const file = ''
const modalDetailShow = false
const modalMessage = false
const modalUpload = false
const documentsList = []

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormDatepicker,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      file,
      modalUpload,
      items,
      statusItems,
      materialRequestId,
      userId,
      messagesList,
      documentsList,
      isLoadingExport: false,
      isLoadingExportFinished: false,
      isLoadingExportInQueue: false,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get("UserRole")
    this.$root.$on("refreshTable", text => {
      console.log(text)
      this.$refs.refMaterialRequestListTable.refresh()
      this.$refs.refMaterialRequestListTableFinished.refresh()
      this.$refs.refMaterialRequestListTableInQueue.refresh()
    })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = "app-material-request"
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewMaterialRequestSidebarActive = ref(false)

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ]

    const {
      fetchMaterialRequests,
      fetchMaterialRequestsFinished,
      fetchMaterialRequestsInQueue,
      fetchMaterialRequestsVoid,
      tableColumns,
      tableColumnsFinished,
      tableColumnsInQueue,
      tableColumnsVoid,
      perPage,
      currentPage,
      perPageFinished,
      currentPageFinished,
      dataMetaFinished,
      searchQueryFinished,
      perPageInQueue,
      currentPageInQueue,
      dataMetaInQueue,
      searchQueryInQueue,
      perPageVoid,
      currentPageVoid,
      dataMetaVoid,
      searchQueryVoid,
      totalItems,
      totalItemsFinished,
      totalItemsInQueue,
      totalItemsVoid,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaterialRequestListTable,
      refMaterialRequestListTableFinished,
      refMaterialRequestListTableInQueue,
      refMaterialRequestListTableVoid,
      refetchData,
      refetchDataFinished,
      refetchDataVoid,
      resolveMaterialRequestRoleVariant,
      resolveMaterialRequestRoleIcon,
      resolveMaterialRequestStatusVariant,
      roleOptions,
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
      startDateFilterFinished,
      endDateFilterFinished,
      startDateFilterInQueue,
      endDateFilterInQueue,
    } = MaterialRequestList()

    return {
      statusOptions,
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      isAddNewMaterialRequestSidebarActive,
      userRole,
      fetchMaterialRequests,
      fetchMaterialRequestsFinished,
      fetchMaterialRequestsInQueue,
      fetchMaterialRequestsVoid,
      tableColumns,
      tableColumnsFinished,
      tableColumnsInQueue,
      tableColumnsVoid,
      perPage,
      currentPage,
      totalItems,
      perPageFinished,
      currentPageFinished,
      dataMetaFinished,
      searchQueryFinished,
      totalItemsFinished,
      perPageInQueue,
      currentPageInQueue,
      dataMetaInQueue,
      searchQueryInQueue,
      totalItemsInQueue,
      totalItemsVoid,
      perPageVoid,
      currentPageVoid,
      dataMetaVoid,
      searchQueryVoid,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaterialRequestListTable,
      refMaterialRequestListTableFinished,
      refMaterialRequestListTableVoid,
      refMaterialRequestListTableInQueue,
      refetchData,
      refetchDataFinished,
      refetchDataVoid,
      avatarText,
      resolveMaterialRequestRoleVariant,
      resolveMaterialRequestRoleIcon,
      resolveMaterialRequestStatusVariant,
      roleOptions,
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
      startDateFilterFinished,
      endDateFilterFinished,
      startDateFilterInQueue,
      endDateFilterInQueue,
    }
  },
  methods: {
    exportMR() {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_MATERIAL_REQUEST}`
      this.isLoadingExport = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilter,
            date_end: this.endDateFilter,
            type: "ongoing",
          },
      }).then(response => {
        let filename = "report_material_request"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
    closeMR(id) {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CLOSE_MATERIAL_REQUEST}`
      const body = {
        id,
        time: moment(),
      }
      axios.post(url, body, {
          headers,
      }).then(response => {
        if (response.data.success === true) {
            this.$refs.refMaterialRequestListTable.refresh()
            this.$refs.refMaterialRequestListTableFinished.refresh()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Close MR Success",
            icon: "CheckIcon",
            variant: "success",
          },
        })
      } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.Message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
      }
      }).finally(() => {
        this.isLoadingExportFinished = false
      })
    },
    exportMRFinished() {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_MATERIAL_REQUEST}`
      this.isLoadingExportFinished = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilterFinished,
            date_end: this.endDateFilterFinished,
            type: "finished",
          },
      }).then(response => {
        let filename = "report_material_request"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "CheckIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExportFinished = false
      })
    },
    exportMRInQueue() {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_MATERIAL_REQUEST}`
      this.isLoadingExportInQueue = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilterInQueue,
            date_end: this.endDateFilterInQueue,
            type: "InQueue",
          },
      }).then(response => {
        let filename = "report_material_request"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExportInQueue = false
      })
    },
    deleteAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
        id: document.document_id,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            this.showDocuments(document.document_material_request_id)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Delete Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.Message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    downloadAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            window.open(response.data.Payload, "_blank")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    deleteMaterialRequest(id) {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_MATERIAL_REQUEST}${id}`,
          { headers },
        )
        .then(response => {
          console.log(response)
          if (response.data.success === true) {
            this.$refs.refMaterialRequestListTable.refresh()
            this.$refs.refMaterialRequestListTableFinished.refresh()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Congratulation",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Your data has been deleted!",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Notification",
                icon: "BellIcon",
                variant: "danger",
                text: "Something went wrong",
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    editMaterialRequest(data) {
      localStorage.setItem("materialRequestDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-material-request-edit" })
    },
    confirmMaterialRequest(data) {
      localStorage.setItem("materialRequestDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-material-request-confirm" })
    },
    sendMaterialRequest(data) {
      localStorage.setItem("materialRequestDetail", JSON.stringify(data))
      this.$router.push({ name: "apps-material-request-send" })
    },
    addNew() {
      this.$router.push({ name: "apps-material-request-create" })
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    showDetail(item) {
      localStorage.setItem("materialRequestDetail", JSON.stringify(item))
      this.$router.push({ name: "apps-material-request-detail" })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get("userPermissions").split(",")
      return permissions.includes(permission) ? true : false
    },
    showMessages(id) {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.materialRequestId = id
      this.modalMessage = true
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_CHATS}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            console.log("Fetching Data")
            if (response.data.data) {
              this.messagesList.splice(0, this.messagesList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Message success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response.data.data.map(value => {
                this.messagesList.push(value)
                return value.material_request_chat_text
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    cutString(string) {
      const lengthOf = string.length
      return lengthOf > 30 ? `${string.substring(0, 30)} ...` : string
    },
    showDocuments(id) {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.materialRequestId = id
      this.modalUpload = true
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}mr/${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            console.log("Fetching Data")
            if (response.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
    sendDocument() {
      const userToken = this.$cookies.get('userToken')
      const header = {
        'Content-Type': 'multipart/form-dataitem',
        Authorization: `Bearer ${userToken}`,
      }
      const formData = new FormData()
      if (typeof this.file[0] !== 'undefined') {
      formData.append('file', this.file[0], this.file[0].name)
      formData.append('document_material_request_id', this.materialRequestId)
      formData.append('time', moment())
    axios
      .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_DOCUMENT}`, formData, { headers: header })
      .then(async response => {
        if (response.data.success === true) {
            this.$refs.refMaterialRequestListTable.refresh()
            this.$refs.refMaterialRequestListTableFinished.refresh()
            this.$refs.refMaterialRequestListTableInQueue.refresh()
            this.$refs.uploadFile.reset()
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}mr/${this.materialRequestId}`,
          { headers: header },
        )
        .then(response2 => {
          if (response2.data.success === true) {
            if (response2.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response2.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Purchase Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please Upload The Document!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async sendMessage() {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const id = this.materialRequestId
      const Message = this.messageBox
      const body = {
        material_request_chat_material_request_id: id,
        material_request_chat_text: Message,
        time: moment(),
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_MATERIAL_REQUEST_CHATS}`,
          body,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.messageBox = ""
            axios
              .get(
                `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_CHATS}${id}`,
                { headers },
              )
              .then(response2 => {
                if (response2.data.success === true) {
                  if (response2.data.data) {
                    messagesList.splice(0, messagesList.length)
                    response2.data.data.map(value => {
                      messagesList.push(value)
                      return value.invoiceMessage
                    })
            this.$refs.refMaterialRequestListTable.refresh()
            this.$refs.refMaterialRequestListTableFinished.refresh()
            this.$refs.refMaterialRequestListTableInQueue.refresh()
                  }
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"
</style>
